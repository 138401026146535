import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className=" ">
        <div className="flex justify-center items-center py-6 pintura">
          {
            rpdata?.gmb?.link.length > 1 ?
              <a href={rpdata?.gmb?.link} target="_blank" rel='noopener noreferrer'>
                <img src={rpdata?.gmb?.img} alt="Not found" width={'250px'} />
              </a>
              : null
          }
        </div>
        {
          rpdata?.dbSocialMedia?.redes?.[0].url.length > 0 || rpdata?.dbSocialMedia?.directorios?.[0].url.length > 0 ?
            <h2 className="text-center pb-5">
              Find Us
            </h2>
            : null
        }
        <div className=" flex justify-center">
          <div className="flex flex-wrap justify-center items-center gap-5 bg-auto md:bg-contain bg-top-[100px]">
            {
              rpdata?.dbSocialMedia?.directorios?.[0].url.length > 0 ?
                rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                  return (
                    <div
                      className="text-center content-directorios"
                      key={index}
                    >
                      <a href={item.url} target="_blank" rel='noopener noreferrer'>
                        <img src={item.image} alt="Not found" width={'250px'} />
                      </a>
                    </div>
                  );
                }) : null
            }
            {
              rpdata?.dbSocialMedia?.redes?.[0].url.length > 0 ?
                rpdata?.dbSocialMedia?.redes.map((item, index) => {
                  return (
                    <div
                      className="text-center content-directorios"
                      key={index}
                    >
                      <a href={item.url} target="_blank" rel='noopener noreferrer'>
                        <img src={item.image} alt="Not Found" width={'250px'} />
                      </a>
                    </div>
                  );
                }) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Directories;
